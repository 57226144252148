@font-face {
  font-family: "The Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/thesans-regular.eot"); /* IE9 Compat Modes */
  src: local("thesans"), local("thesans"),
    url("../fonts/thesans-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/thesans-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/thesans-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/thesans-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/thesans-regular.svg#thesans")
      format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "The Sans";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/thesans-bold.eot"); /* IE9 Compat Modes */
  src: local("thesans"), local("thesans"),
    url("../fonts/thesans-bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/thesans-bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/thesans-bold.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/thesans-bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/thesans-bold.svg#thesans")
      format("svg"); /* Legacy iOS */
}
