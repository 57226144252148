body {
  margin: 0;
  font-family: 'The Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: theme-color('primary');
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: bold;
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  #main {
    flex: 1 0 auto;
  }
}