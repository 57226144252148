.loader {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  position: relative;
  
  &-inner {
    position: inherit;
    width: inherit;
    height: inherit;
    background: theme-color('primary');
    border-radius: inherit;
    z-index: 1;
  }

  &-expander {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    width: inherit;
    background: #B6CFE4;
    border-radius: inherit;
    z-index: 0;
    animation: scales 1.3s ease-in infinite;
  }

}

@keyframes scales {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 1;
  }
  80%,
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}