// Override default variables before the import
$body-bg: #ffffff;

@import "variables";
@import "fonts";
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import "reset";
@import "layout";
@import "loader";

.legal-page {
  h1 {
    margin-bottom: 2rem;
  }
  h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
}

.btn {
  font-weight: bold;
  font-size: 1.125rem;
  padding: 1rem 2rem;

  &:disabled,
  &.disabled {
    background: $pink;
    border: none;

    &:hover {
      background: $pink;
    }
  }

  &-primary {
    background: $pink;
    border: none;

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:focus {
      background: darken($pink, 5%);
    }
  }
}

.plans {
  .card {
    border-radius: 0;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(theme-color("primary"), 0.2);

    &-header {
      position: relative;
      border-radius: 0 !important;
      background: $primary;
      height: 60px;
      padding: 1.5rem;

      .badge {
        transform: translateY(-60%);
        width: 4rem;
        height: 4rem;
        border-radius: 2rem;
        line-height: 2.4rem;
        background: $pink;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      padding: 2.5rem 1.5rem;

      ul {
        font-size: 1.125rem;
        font-weight: bold;

        li {
          position: relative;
          padding-left: 1rem;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0.6rem;
            left: 0;
            background: $pink;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.25rem;
            display: block;
          }
        }
      }

      p {
        margin-bottom: 0;
        margin-top: auto;
      }
    }

    &-footer {
      border: none;
      background: $light-blue;
      padding: 1.5rem;
      box-shadow: 0 -2px 4px 0 rgba(theme-color("primary"), 0.2);

      .btn {
        width: 100%;
      }
    }
  }
}

.jumbotron {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(theme-color("primary"), 0.2);
  border-radius: 0;
  padding: 2rem;

  &.has-error {
    padding-top: 0;

    .error {
      margin: 0 -2rem 2rem -2rem;
      background: theme-color("danger");
      color: #ffffff;
      font-size: 0.8rem;
      padding: 1rem 2rem;
    }
  }
}

.btn-group {
  .btn {
    cursor: pointer;
  }
}

.CardForm .invalid-feedback {
  display: block !important;
}

.highlighted {
  color: $pink;

  &.small {
    font-size: 0.6em;
  }
}

.Message {
  width: 100%;
}

.was-validated .form-control:valid:not([required]),
.form-control.is-valid:not([required]) {
  background-image: none !important;
  border-color: #ced4da !important;
}

.form-control.react-datepicker-wrapper {
  padding: 0 !important;
  border: none !important;

  input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.reportWrapper {
  .btn {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .form-row {
    align-items: flex-end;
  }
}

.warning-alert {
  background-color: rgba(255,229,100,.3);
  color: #1a1a1a;
  border-left: 4px solid #ffe564;
  padding: 1.2rem;
}
