body {
  background: $light-blue;
}

.navbar-brand {
  padding: 2rem 0;
  @include media-breakpoint-up('md') {
    display: flex;
  }
}

img.logo-image {
  height: 60px;
  width: auto;
}

.logo-title {
  align-items: flex-end;

  @include media-breakpoint-down('md') {
    display: block !important;
    margin-top: 1rem !important;
    font-size: 1rem;
  }
}

#main {
  padding: 5rem 0;
}

footer {
  color: #ffffff;
  font-size: 0.875rem;

  .level-1 {
    background: theme-color('secondary');
    padding: 2rem 0;

    .container {
      .logo {
        margin-bottom: 1rem;
      }

      @include media-breakpoint-up('md') {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nav {
          justify-content: flex-end;
        }

        .logo {
          margin-bottom: 0;
        }
      }
    }
  }

  .level-2 {
    background: lighten(theme-color('secondary'), 10%);
    padding: 1rem 0;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    position: relative;
    color: inherit;

    &:after {
      content: '';
      position: absolute;
      left: $grid-gutter-width / 2;
      right: $grid-gutter-width / 2;
      bottom: 0;
      height: 2px;
      background: #ffffff;
      opacity: 0;
      transition: all ease-in-out .2s;
      transform: translateY(5px);
    }

    &.disabled {
      color: #ffffff !important;

      &:after {
        content: none;
      }
    }

    &:hover {
      color: #ffffff !important;

      &:after {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.form-control {
  &.has-error {
    border-color: $red;
    box-shadow: 0 0 5px $red;
  }
}

.error-message {
  color: $red;
}